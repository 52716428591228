@import '~/node_modules/normalize.css';
@import '~/node_modules/jquery-ui-bundle/jquery-ui.theme.css';
@import '~/node_modules/slick-carousel/slick/slick';
@import './animations.scss';
@import './mixins.scss';
@import './fonts.scss';

* {
  color: #000;
  font-family: 'suisse_intlregular';
  font-size: 13px !important;
  font-weight: normal;
  margin: 0;
  padding: 0;
}

header {
  @include clearfix();
  padding: 0 30px;

  h1,
  h2 {
    // font-size: 24px;
    // line-height: 26px;
    line-height: 17px;
    margin: 0;
    margin-block-start: 0 !important;
    margin-block-end: 0 !important;
  }

  h1 {
    float: left;
    text-transform: uppercase;
  }

  h2 {
    float: right;
    text-align: right;
  }
}

footer {
  margin-top: 40px;
  padding: 0 30px;

  a {
    // font-size: 24px;
    text-decoration: none;
  }
}

#main {
  padding: 50px 0;
}

.Accordion {
  border-bottom: 1px solid #000;
  margin-top: 80px;
  position: relative;

  &__status {
    border: 1px solid #000;
    border-radius: 20px;
    padding: 8px 5px;
    position: absolute;
    right: 15px;
    text-align: center;
    top: 5px;
    width: 80px;

    &--pending {
      border-color: #BFCDDB;
      color: #BFCDDB;
    }

    &--in-progress {
      border-color: #ffb24f;
      color: #ffb24f;
    }

    &--complete {
      border-color: #8EBC87;
      color: #8EBC87;
    }
  }

  &__controls {
    @include clearfix();

    &--desktop {
      margin-left: 38px;
      margin-top: 20px;

      .Accordion__arrow {
        background-image: url('../images/arrow-black.svg');
        border: 1px solid #000;
      }
    }

    &--mobile {
      bottom: 10px;
      display: none;
      margin-left: 0;
      margin-top: 0;
      position: absolute;
      right: 10px;

      .Accordion__arrow {
        background-image: url('../images/arrow-white.svg');
        border: 1px solid #fff;
      }
    }
  }

  &__arrow {
    background-repeat: no-repeat;
    background-position: center 8px;
    background-size: 11px 13px;
    cursor: pointer;
    display: block;
    float: left;
    height: 28px;
    width: 28px;

    &:first-child {
      background-position: center 7px;
      transform: rotate(180deg);
    }

    &:last-child {
      border-left: 0;
    }
  }

  &__content {
    @include clearfix();
  }

  &__url {
    display: block;
    // font-size: 13px;
    margin-left: 38px;
    margin-top: 20px;
  }

  &__left {
    line-height: 0;
    max-width: 623px;
    padding-left: 30px;
  }

  &__right {
    padding-right: 30px;
  }

  &__left,
  &__right {
    float: left;
    position: relative;
    width: calc(50% - 30px);
  }

  &__image {
    width: 100%;
  }

  &__description {
    // font-size: 13px;
    line-height: 17px;
    margin-left: 38px;
    max-width: 300px;
  }

  .ui-accordion-header {
    background-color: transparent !important;
    border-color: #000;
    border-radius: 0 !important;
    border-width: 1px 0 0 0 !important;
    color: #000 !important;
    cursor: pointer;
    // font-size: 24px;
    outline: 0 !important;
    padding: 14px 30px;
    position: relative;
  }

  .ui-widget-content {
    border: 0 !important;
    color: #000 !important;
    padding: 0 0 30px 0;
  }

  [data-total-images="0"],
  [data-total-images="1"] {
    .Accordion__controls {
      display: none;
    }
  }
}

@media only screen and (max-width: 768px) {
  header {
    padding: 0 15px;;

    h2 {
      clear: both;
      float: left;
      padding-bottom: 20px;
      padding-top: 20px;
      text-align: left;
    }
  }

  footer {
    padding-left: 15px;
    padding-right: 15px;
  }

  #main {
    padding: 80px 0;
  }

  .Accordion {
    margin-top: 30px;

    // h3 {
    //   padding-left: 15px !important;
    //   padding-right: 15px !important;
    // }

    &__left,
    &__right {
      float: left;
      margin-left: 15px;
      max-width: unset;
      padding: 0;
      width: calc(100% - 30px);
    }

    &__url {
      margin-left: 0;
    }

    &__description {
      margin-left: 0;
      margin-top: 20px;
      max-width: unset;
    }

    &__controls {
      &--desktop {
        display: none;
      }

      &--mobile {
        display: block;
      }
    }

    .ui-accordion-header {
      padding: 14px 15px;
    }
  }
}