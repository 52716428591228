/**
 * Forces an element to self-clear its children
 *
 * Usage:
 * .selector {
 *   @include clearfix;
 * }
 */
@mixin clearfix() {
  &::after {
    content: '';
    display: table;
    clear: both;
  }

  // stylelint-disable-next-line
  *zoom: 1;
}

/**
 * Setup a background image in cover mode
 *
 * Usage:
 * .selector {
 *   @include cover-bkg();
 * }
 */
@mixin cover-bkg($horizontally: center, $vertically: center) {
  background-position: $horizontally $vertically;
  background-repeat: no-repeat;
  -ms-background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  background-size: cover;
}

/**
 * Vertically align an element in a container, position must be defined
 *
 * Usage:
 * .selector {
 *   @include vertical-align();
 * }
 */
@mixin vertical-align($position: relative) {
  position: $position;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}