.fade-in {
  animation: fadeIn ease 2s;
  -webkit-animation: fadeIn ease 2s;
  -moz-animation: fadeIn ease 2s;
  -o-animation: fadeIn ease 2s;
  -ms-animation: fadeIn ease 2s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  // 20% {
  //   opacity: 0;
  // }
  100% {
    opacity: 1;
  }
}


.fade-out {
  animation: fadeOut ease 0.5s;
  -webkit-animation: fadeOut ease 0.5s;
  -moz-animation: fadeOut ease 0.5s;
  -o-animation: fadeOut ease 0.5s;
  -ms-animation: fadeOut ease 0.5s;
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
