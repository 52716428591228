// @font-face {
//   font-family: 'suisse_bp_intlregular';
//   src: url('../fonts/suisse_bp_intl_regular-webfont.woff2') format('woff2'),
//        url('../fonts/suisse_bp_intl_regular-webfont.woff') format('woff');
//   font-weight: normal;
//   font-style: normal;
// }

@font-face {
  font-family: 'suisse_intlregular';
  src: url('../fonts/SuisseIntl-Regular-WebM.woff2') format('woff2'),
       url('../fonts/SuisseIntl-Regular-WebM.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}